"use strict";

document.addEventListener("DOMContentLoaded", function() {

    $.ajaxSetup({
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    });

    $(document).on("click", "#_forgotpw" , function() {
        $('.login-form').hide();
        $('.forgot-form').show();
    });

    $(document).on("click", "#_loginUser" , function() {
        $('.forgot-form').hide();
        $('.login-form').show();
    });

    var KTLoginGeneral = function() {

        var login = $('#kt_login');

        var showErrorMsg = function(form, type, msg, closable) {
            var alert = $('<div class="alert alert-' + type + ' alert-dismissible" role="alert">\
                <div class="alert-text">'+msg+'</div>\
            </div>');

            if (closable) {
                alert.append('<div class="alert-close">\
                    <i class="flaticon2-cross kt-icon-sm" data-dismiss="alert"></i>\
                </div>')
            }

            form.find('.alert').remove();
            alert.prependTo(form);
            alert.find('span').html(msg);
        }

        var handleChangePasswordSubmit = function() {
            $('.kt_login_change_submit').click(function(e) {
                e.preventDefault();
                var btn = $(this);
                var form = $(this).closest('form');
                const password = $('#new_password').val();
                const forgot_key = $('#forgot_key').val();


                btn.attr('disabled', true);

                $.ajax({
                    url: '/v1/newPassword',
                    dataType: 'json',
                    method: 'POST',
                    data: {
                        forgot_key,
                        password
                    },
                    success: function(response, status, xhr, $form) {
                        console.log(response);
                        if (!response.ok) {
                            btn.attr('disabled', false);
                            showErrorMsg(form, 'danger', response.description, true);
                        } else {
                            showErrorMsg(form, 'success', 'Successfully changed! Redirecting you to login page...', true);
                            btn.attr('disabled', false);
                            btn.hide();
                            $('.set').removeClass(['d-block', 'd-md-block']);
                            $('.set').addClass('d-none');
                            $('#new_password').hide();
                            setTimeout(function() {
                                window.location.replace('/login');
                            }, 4000)
                        }
                    }
                });
            })
        }

        var handleSignInFormSubmit = function() {

            const loginHandler = function(e) {
                e.preventDefault();
                var btn = $('.login_btn_submit');
                var form = btn.closest('form');
                let email = $('#email').val();
                let password = $('#password').val();
            
                btn.attr('disabled', true);

                swal.fire({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    text: 'Login in progress...',
                    icon: 'info',
                    timerProgressBar: true,
                    timer: 3000,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', swal.stopTimer)
                        toast.addEventListener('mouseleave', swal.resumeTimer)
                    }
                });
                
            
                $.ajax({
                    url: '/v1/signIn',
                    dataType: 'json',
                    method: 'POST',
                    data: {
                        email,
                        password
                    }
                })
                .done(function(response, status, xhr, $form) {
                    if (!response.ok) {
                        swal.fire('Incorrect e-mail or password. Please try again.');
                        btn.removeAttr('disabled');
                    } else {
                        window.location.replace('/');
                    }
                })
                .fail(function(jqXHR, textStatus, errorThrown) {
                    if(jqXHR.status === 429) {
                        var errorResponse = JSON.parse(jqXHR.responseText);
                        swal.fire({icon: 'error', title: 'Oops...', text: errorResponse.description});
                    } else {
                        swal.fire({icon: 'error', title: 'Oops...', text: 'An unexpected error occurred'});
                    }
            
                    btn.removeAttr('disabled');
                });
            }
            
            $('.login-form .float-form').submit((e) => loginHandler(e))
            $('.login_btn_submit').click((e) => loginHandler(e));
        }

        var handleForgotFormSubmit = function() {
        	const forgotHandler = (e) => {
        		e.preventDefault();
                var btn = $(this);
                var form = $('.kt_login_forgot_form');
                let email = $('#kt_email').val();

                $.ajax({
                    url: '/v1/resetPassword',
                    method: 'POST',
                    dataType: 'json',
                    data: {
                        email: $('#kt_email').val()
                    }
                })
                .done(function(response, status, xhr, $form) {
                    if (response.ok) {
                        $("#forgotEmailEntered").text($('#kt_email').val());
                        showErrorMsg(form, 'success', $("#txtForForgotAlert").text().replace("%{email}", $('#kt_email').val()), true);
                        $('.forgot-fields').hide();
                        btn.hide();
                        $('#p-forgot').hide();
                    } else {
                        showErrorMsg(form, 'warning', response.description);
                    }
                })
                .fail(function(jqXHR, textStatus, errorThrown) {
                    if(jqXHR.status === 429) {
                        var errorResponse = JSON.parse(jqXHR.responseText);
                        swal.fire({icon: 'error', title: 'Oops...', text: errorResponse.description});
                    } else {
                        swal.fire({icon: 'error', title: 'Oops...', text: 'An unexpected error occurred'});
                    }
                
                    btn.removeAttr('disabled');
                });
        	}
            $('.kt_login_forgot_submit').click((e) => forgotHandler(e));
        }

        // Public Functions
        return {
            // public functions
            init: function() {
                handleSignInFormSubmit();
                handleForgotFormSubmit();
                handleChangePasswordSubmit();
            }
        };
    }();

    // Class Initialization
    jQuery(document).ready(function() {
        KTLoginGeneral.init();
    });
});
