require("jquery")


import '/public/template/plugins/custom/prismjs/prismjs.bundle.js'
import '/public/template/js/scripts.bundle.js'

import 'bootstrap'

import swal from 'sweetalert2';
window.swal = swal;

window.swal = swal.mixin({
  heightAuto: false
});

import './plugins/jquery.passwordRequirements.js'
import './plugins/login.js'


document.addEventListener("DOMContentLoaded", function() {
	$.ajaxSetup({
		headers: {
			'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
		}
	});
})
